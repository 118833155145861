<template>
  <div>
    <!-- 上半部 -->
    <div id="PurchaseOrderTopArea" class="row mb-1">
      <!-- 按鈕 -->
      <div class="col-md-5 col-12 mb-1">
        <!-- 批次操作 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="MerchandiseBatch"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            批次操作
          </button>
          <ul class="dropdown-menu" aria-labelledby="MerchandiseBatch">
            <li>
              <button class="dropdown-item" @click="showModal('batchDel')">
                刪除
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('allInStock')">
                全部入庫
              </button>
            </li>
            <li v-if="d4DataTable === 'header'">
              <button class="dropdown-item" @click="showModal('mergeHeaders')">
                合併叫貨單
              </button>
            </li>
          </ul>
        </div>
        <!-- 執行 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="MerchandiseAction"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            執行
          </button>
          <ul class="dropdown-menu" aria-labelledby="MerchandiseAction">
            <li>
              <button class="dropdown-item" @click="showModal('exportExcel')">
                匯出
              </button>
            </li>
          </ul>
        </div>
        <!-- 進階搜尋 -->
        <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showModal('advancedSearch')"
        >
          進階搜尋
        </button>
        <!-- 表格呈現方式 -->
        <div class="d-inline-block me-1">
          <button
            class="tw-btn"
            :class="{
              'tw-btn-danger': d4DataTable === 'body',
              'tw-btn-success': d4DataTable === 'header',
            }"
            @click="changeMode"
          >
            <span v-if="d4DataTable === 'body'">明細模式</span>
            <span v-else>叫貨單模式</span>
          </button>
        </div>
      </div>
      <!-- 訊息 -->
      <div class="col-md-7 col-12 mb-1 fw-bolder">
        <p>
          搜尋條件:
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.merchandiseName"
          >
            商品名稱 {{ recordAdvancedSearchData.merchandiseName }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.supplier"
          >
            供應商: {{ recordAdvancedSearchData.supplier.name }}
          </span>
          <span class="badge bg-primary me-1 mb-1" v-if="d4DataTable === 'header'">
            叫貨單狀態: {{ recordAdvancedSearchData.singleSelect.headerStatus }}
          </span>
          <span class="badge bg-primary me-1 mb-1"  v-if="d4DataTable === 'body'">
            叫貨明細狀態: {{ recordAdvancedSearchData.singleSelect.bodyStatus }}
          </span>
        </p>
        <p>
          <span v-if="recordAdvancedSearchData.timeRange.switch">
            時間區間:
            <span
              v-if="
                recordAdvancedSearchData.timeRange.startTime &&
                recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.startTime }}~{{
                recordAdvancedSearchData.timeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.timeRange.startTime &&
                !recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.startTime }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.timeRange.startTime &&
                recordAdvancedSearchData.timeRange.endTime
              "
              >{{ recordAdvancedSearchData.timeRange.endTime }}以前</span
            >&ensp;
          </span>
        </p>
      </div>
    </div>
    <!-- 單頭表格 -->
    <div class="tw-container" v-if="d4DataTable === 'header'">
      <DataTable
        class="p-datatable-sm"
        scrollable
        :scrollHeight="`${scrollHeight}px`"
        :value="purchaseOrderList"
        dataKey="id"
        :loading="dataTabelLoading"
        :rowHover="true"
        :paginator="true"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rows="D4Row"
        :rowsPerPageOptions="[20, 50, 100]"
        currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
        v-model:filters="searchGlobal"
        filterDisplay="menue"
        stateStorage="local"
        stateKey="dt-state-purchaseOrder-local"
        sortField="createTime"
        :sortOrder="-1"
        v-model:selection="selectHeaders"
        v-model:expandedRows="expandedRows"
        stripedRows
      >
        <template #header>
          <div class="d-flex align-items-center mb-2">
            <FieldFilter
              whitchOneTable="purchaseHeader"
              :D4FieldFilter="fieldFilter"
            ></FieldFilter>
            <div class="w-100">
              <p>表格搜尋</p>
              <input
                class="form-control me-2"
                v-model="searchGlobal['global'].value"
                placeholder="表格搜尋: 叫貨編號，供應商，叫貨時間"
                style="max-width: 99%"
              />
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div>
              <div>
                <button
                  class="tw-btn tw-btn-success me-2 mb-2"
                  @click="expandAll"
                >
                  ＋展開全部</button
                ><button
                  class="tw-btn tw-btn-success mb-2"
                  @click="collapseAll"
                >
                  －收合全部
                </button>
              </div>
            </div>
            <!-- <div>
              <button
                class="tw-btn tw-btn-success me-2 mb-2"
                @click="updateWarehousingQuantity('multiple', 'min')"
              >
                全數歸0
              </button>
              <button
                class="tw-btn tw-btn-success me-2 mb-2"
                @click="updateWarehousingQuantity('multiple', 'max')"
              >
                全數到貨
              </button>
              <button
                class="tw-btn tw-btn-success mb-2"
                @click="stockInBodies('all')"
              >
                確定入庫
              </button>
            </div> -->
          </div>
        </template>
        <!-- 展開/收合 -->
        <Column
          v-if="fieldFilter[0].selected"
          :expander="true"
          style="min-width: 50px; max-width: 50px"
        />
        <!-- 多選 -->
        <Column
          v-if="fieldFilter[0].selected"
          selectionMode="multiple"
          style="min-width: 50px; max-width: 50px"
        ></Column>
        <!-- 叫貨編號 -->
        <Column
          v-if="fieldFilter[1].selected"
          field="id"
          header="叫貨編號"
          sortable
          style="min-width: 80px; max-width: 120px"
        ></Column>
        <!-- 叫貨明細 -->
        <Column
          v-if="fieldFilter[2].selected"
          field=""
          header="叫貨明細"
          style="min-width: 100px"
        >
          <template #body="{ data }">
            <p>運費: {{ data.shippingCost }}</p>
            <p>金額調整: {{ data.adjustment }}</p>
            <p>明細總金額: {{ $methods.numberToFixed(data.price) }}</p>
            <hr />
            <p class="fw-bolder text-danger">
              應付金額: {{ $methods.numberToFixed(data.shouldBePay) }}
            </p>
          </template>
        </Column>
        <!-- 供應商 -->
        <Column
          v-if="fieldFilter[3].selected"
          field="supplierName"
          header="供應商"
          sortable
          style="min-width: 100px"
        >
          <template #body="{ data }">
            <span
              class="pointer can-click"
              @click="showModal('checkSupplierInfo', data.supplier)"
              >{{ data.supplier.name }}</span
            >
          </template>
        </Column>
        <Column
          v-if="fieldFilter[4].selected"
          field="note"
          header="備註"
          style="min-width: 150px"
        ></Column>
        <!-- 叫貨時間 -->
        <Column
          v-if="fieldFilter[5].selected"
          field="createTime"
          header="叫貨時間"
          sortable
          style="min-width: 230px"
          :sortOrder="-1"
        >
        </Column>
        <!-- 操作 -->
        <Column
          v-if="fieldFilter[6].selected"
          header="操作"
          style="min-width: 230px"
        >
          <template #body="{ data }">
            <button
              class="tw-btn tw-btn-success me-2"
              @click="showModal('editHeader', data)"
            >
              編輯
            </button>
            <button
              class="tw-btn tw-btn-danger"
              @click="showModal('delNotInStock', data)"
            >
              刪除未到貨數量
            </button>
          </template>
        </Column>
        <!-- 單身 -->
        <template #expansion="slotProps">
          <ul>
            <li
              class="border-bottom pb-2"
              v-for="data in slotProps.data.purchaseOrderBodies"
              :key="data.id"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :value="data"
                v-model="selectBodies"
              />
              <div class="row">
                <!-- 商品圖 -->
                <div class="col-1 d-flex align-items-center">
                  <Image
                    :imageHash="data.frontImageHash"
                    size="s"
                    v-if="data.frontImageHash"
                    :alt="data.frontImageHash"
                    style="width: 50px; height: 50px"
                  ></Image>
                  <img
                    src="@/assets/other-images/noImg.png"
                    alt=""
                    v-else
                    style="width: 50px; height: 50px"
                  />
                </div>
                <!-- 單身資訊 -->
                <div class="col-3 d-flex align-items-center">
                  <ul>
                    <li class="mb-2">
                      明細編號: <span>{{ data.id }}</span>
                    </li>
                    <li class="mb-2">
                      名稱:
                      <router-link
                        :title="`商品備註: ${data.merchandise.note}`"
                        :to="`/seller/store/${storeId}/merchandiseInfo/detail?merchandiseId=${data.merchandise.id}`"
                        class="fw-bolder text-break pointer"
                        :class="{ 'text-danger': data.merchandise.deleted }"
                        target="_blank"
                        >{{ data.merchandiseName }}</router-link
                      >
                    </li>
                    <li>
                      款式:
                      <span
                        v-for="style in data.stock.merchandiseStyles"
                        :key="style.id"
                      >
                        {{ style.name }}&ensp;
                      </span>
                    </li>
                  </ul>
                </div>
                <!-- 叫貨數量 -->
                <div class="col-4 d-flex align-items-center">
                  <ul class="me-3">
                    <li class="mb-2">
                      到貨: <span>{{ data.stockInQuantity }}</span>
                    </li>
                    <li>
                      未到:
                      <span>{{
                        Math.max(data.quantity - data.stockInQuantity, 0)
                      }}</span>
                    </li>
                  </ul>
                  <div class="row">
                    <div class="col-12 p-0 d-flex mb-2">
                      <div class="me-2">
                        <button
                          class="tw-btn tw-btn-success"
                          @click="stockInBodies('single', data, false)"
                        >
                          全到
                        </button>
                      </div>
                      <div class="me-2">
                        <button
                          class="tw-btn tw-btn-success"
                          @click="stockInBodies('single', data, true)"
                        >
                          全到並配單
                        </button>
                      </div>
                    </div>
                    <div class="col-12 p-0 d-flex">
                      <div class="me-2">
                        <button
                          class="tw-btn tw-btn-success"
                          @click="showModal('editBody', data)"
                        >
                          編輯
                        </button>
                      </div>
                      <div class="me-2">
                        <button
                          class="tw-btn tw-btn-danger"
                          @click="showModal('singleDel', data)"
                        >
                          刪除
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 入庫 -->
                <!-- <div
                  class="
                    col-4
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <div>
                    <span
                      >{{ data.quantity }} X
                      {{ $methods.numberToFixed(data.cost) }}</span
                    >
                  </div>
                  <div>
                    <button
                      class="tw-btn p-1"
                      @click="updateWarehousingQuantity('single', 'min', data)"
                    >
                      MIN
                    </button>
                    <input
                      class="w-50"
                      type="number"
                      v-model="data.warehousingQuantity"
                    />
                    <button
                      class="tw-btn p-1"
                      @click="updateWarehousingQuantity('single', 'max', data)"
                    >
                      MAX
                    </button>
                  </div>
                </div> -->
              </div>
            </li>
          </ul>
        </template>
        <template #empty>
          <p class="text-center fw-bolder text-primary">
            目前尚未有任何資料 0..0
          </p>
        </template>
      </DataTable>
    </div>
    <!-- 單身表格 -->
    <div class="tw-container" v-else>
      <DataTable
        class="p-datatable-sm"
        scrollable
        :scrollHeight="`${scrollHeight}px`"
        :value="purchaseOrderBodies"
        dataKey="id"
        :loading="dataTabelLoading"
        :rowHover="true"
        :paginator="true"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rows="D4Row"
        :rowsPerPageOptions="[20, 50, 100]"
        currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
        v-model:filters="searchGlobal"
        filterDisplay="menue"
        stateStorage="local"
        stateKey="dt-state-purchaseOrder-bodies-local"
        sortField="createTime"
        :sortOrder="-1"
        v-model:selection="selectBodies"
        stripedRows
      >
        <template #header>
          <div class="d-flex align-items-center mb-2">
            <FieldFilter
              whitchOneTable="purchaseBody"
              :D4FieldFilter="fieldFilterB"
            ></FieldFilter>
            <div class="w-100">
              <p>表格搜尋</p>
              <input
                class="form-control me-2"
                v-model="searchGlobal['global'].value"
                placeholder="表格搜尋: 商品名稱，商品款式，供應商，叫貨時間"
                style="max-width: 99%"
              />
            </div>
          </div>
        </template>
        <!-- 多選 -->
        <Column
          v-if="fieldFilterB[0].selected"
          selectionMode="multiple"
          style="min-width: 50px; max-width: 50px"
        ></Column>
        <!-- 商品圖 -->
        <Column
          v-if="fieldFilterB[1].selected"
          field=""
          header="圖片"
          style="min-width: 50px; max-width: 50px"
        >
          <template #body="{ data }">
            <Image
              :imageHash="data.frontImageHash"
              size="s"
              v-if="data.frontImageHash"
              :alt="data.frontImageHash"
              style="width: 50px; height: 50px"
            ></Image>
            <img
              src="@/assets/other-images/noImg.png"
              alt=""
              v-else
              style="width: 50px; height: 50px"
            />
          </template>
        </Column>
        <!-- 叫貨明細 -->
        <Column
          v-if="fieldFilterB[2].selected"
          field="merchandiseName"
          header="叫貨明細"
          sortable
          style="min-width: 150px"
        >
          <template #body="{ data }">
            <ul class="ps-2">
              <li class="mb-2">
                明細編號: <span>{{ data.id }}</span>
              </li>
              <li class="mb-2">
                名稱:
                <router-link
                  :title="`商品備註: ${data.merchandise.note}`"
                  :to="`/seller/store/${storeId}/merchandiseInfo/detail?merchandiseId=${data.merchandise.id}`"
                  class="fw-bolder text-break pointer"
                  :class="{ 'text-danger': data.merchandise.deleted }"
                  target="_blank"
                  >{{ data.merchandiseName }}</router-link
                >
              </li>
            </ul>
          </template>
        </Column>
        <!-- 款式 -->
        <Column
          v-if="fieldFilterB[6].selected"
          field="merchandiseStyle"
          header="款式"
          sortable
          style="min-width: 150px"
        >
          <template #body="{ data }">
            {{ data.merchandiseStyle }}
          </template>
        </Column>
        <!-- 叫貨數量 -->
        <Column
          v-if="fieldFilterB[3].selected"
          field=""
          header="叫貨數量"
          style="min-width: 350px"
        >
          <template #body="{ data }">
            <div class="d-flex align-items-center">
              <ul class="me-3">
                <li class="mb-2">
                  到貨: <span>{{ data.stockInQuantity }}</span>
                </li>
                <li>
                  未到:
                  <span>{{
                    Math.max(data.quantity - data.stockInQuantity, 0)
                  }}</span>
                </li>
              </ul>
              <div class="row">
                <div class="col-12 p-0 d-flex mb-2">
                  <div class="me-2">
                    <button
                      class="tw-btn tw-btn-success"
                      @click="stockInBodies('single', data, false)"
                    >
                      全到
                    </button>
                  </div>
                  <div class="me-2">
                    <button
                      class="tw-btn tw-btn-success"
                      @click="stockInBodies('single', data, true)"
                    >
                      全到並配單
                    </button>
                  </div>
                </div>
                <div class="col-12 p-0 d-flex">
                  <div class="me-2">
                    <button
                      class="tw-btn tw-btn-success"
                      @click="showModal('editBody', data)"
                    >
                      編輯
                    </button>
                  </div>
                  <div class="me-2">
                    <button
                      class="tw-btn tw-btn-danger"
                      @click="showModal('singleDel', data)"
                    >
                      刪除
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Column>
        <!-- 供應商 -->
        <Column
          v-if="fieldFilterB[4].selected"
          field="supplierName"
          header="供應商"
          sortable
          style="min-width: 150px; max-width: 150px"
        >
          <template #body="{ data }">
            <span
              class="pointer can-click text-break"
              @click="showModal('checkSupplierInfo', data.supplier)"
              >{{ data.supplierName }}</span
            >
          </template>
        </Column>
        <!-- 單頭建立時間 -->
        <Column
          v-if="fieldFilterB[5].selected"
          field="createTime"
          header="叫貨時間"
          sortable
          style="min-width: 200px; max-width: 200px"
          :sortOrder="-1"
        >
        </Column>
        <template #empty>
          <p class="text-center fw-bolder text-primary">
            目前尚未有任何資料 0..0
          </p>
        </template>
      </DataTable>
    </div>
  </div>
  <!-- 編輯單頭的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editHeaderModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">編輯單頭</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 選擇供應商 -->
          <div class="mb-2">
            <select
              class="form-control"
              v-if="!suppliers.loading"
              v-model="editHeader.supplierId"
            >
              <option
                :value="data.id"
                v-for="data in suppliers.data"
                :key="data.id"
              >
                {{ data.name }}
              </option>
            </select>
            <AreaLoading v-else></AreaLoading>
          </div>
          <!-- 運費/金額調整 -->
          <div class="mb-2 row">
            <div class="col-6 p-0">
              <div class="form-floating mb-3">
                <input
                  onwheel="this.blur()"
                  type="number"
                  class="form-control"
                  id="HeaderShippingCost"
                  placeholder="運費"
                  min="0"
                  v-model="editHeader.shippingCost"
                  @input="
                    editHeader.shippingCost = $methods.numberToFixed(
                      editHeader.shippingCost
                    )
                  "
                />
                <label for="HeaderShippingCost">運費</label>
              </div>
            </div>
            <div class="col-6 p-0">
              <div class="form-floating mb-3">
                <input
                  onwheel="this.blur()"
                  type="number"
                  class="form-control"
                  id="HeaderAdjustment"
                  placeholder="金額調整"
                  v-model="editHeader.adjustment"
                  @change="
                    editHeader.adjustment = $methods.numberToFixed(
                      editHeader.adjustment
                    )
                  "
                />
                <label for="HeaderAdjustment">金額調整</label>
              </div>
            </div>
          </div>
          <!-- 單身金額/應付金額 -->
          <div class="mb-2 row">
            <div class="col-6 p-0">
              <div class="form-floating mb-3">
                <input
                  onwheel="this.blur()"
                  type="number"
                  class="form-control"
                  id="BodyTotal"
                  placeholder="明細金額"
                  :value="editHeader.bodyTotal"
                  disabled
                />
                <label for="BodyTotal">明細金額</label>
              </div>
            </div>
            <div class="col-6 p-0">
              <div class="form-floating mb-3">
                <input
                  onwheel="this.blur()"
                  type="number"
                  class="form-control"
                  id="HeaderShouldBePay"
                  placeholder="應付金額"
                  :value="editHeader.shouldBePay"
                  disabled
                />
                <label for="HeaderShouldBePay">應付金額</label>
              </div>
            </div>
          </div>
          <!-- 備註 -->
          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder="備註(300字)"
              id="HeaderNote"
              style="height: 100px"
              maxlength="300"
              v-model="editHeader.note"
            ></textarea>
            <label for="HeaderNote">備註(300字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateHerder"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯單身的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editBodyModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">編輯明細</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="alert alert-primary">
            <p class="text-black fw-bolder tw-text-size16 mb-2">
              商品名稱: {{ editbody.name }}
            </p>
            <p class="text-black fw-bolder tw-text-size16">
              商品款式: {{ editbody.style }}
            </p>
          </div>
          <!-- 成本 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="BodyCost"
              placeholder="成本"
              min="0"
              v-model="editbody.cost"
              @input="editbody.cost = $methods.numberToFixed(editbody.cost)"
            />
            <label for="BodyCost">成本</label>
          </div>
          <!-- 已到貨數量 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="BodyArrivalQuantity"
              placeholder="已到貨數量"
              v-model="editbody.stockInQuantity"
              pattern="[0-9]*"
              oninput="this.value=this.value.replace(/\D/g,'')"
            />
            <label for="BodyArrivalQuantity">已到貨數量</label>
          </div>
          <!-- 叫貨數量 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="BodyOrderGoods"
              placeholder="叫貨數量"
              v-model="editbody.quantity"
              pattern="[0-9]*"
              oninput="this.value=this.value.replace(/\D/g,'')"
            />
            <label for="BodyOrderGoods">叫貨數量</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateBody(true)"
          >
            確認並配單
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateBody(false)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delBodiesModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="modal-title border-start ps-2 border-5 border-danger text-danger fw-bold"
          >
            刪除
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="alert alert-warning">
            <p class="mb-3" v-if="selectHeaders.length > 0">
              選擇到的叫貨單:
              <span v-for="data in selectHeaders" :key="data.id"
                >{{ data.id }}&ensp;</span
              >
            </p>
            <p v-if="selectBodies.length > 0">
              選擇到的明細:
              <span v-for="data in selectBodies" :key="data.id"
                >{{ data.id }}&ensp;</span
              >
            </p>
          </div>
          <p>確定要刪除這些嗎 ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="delBodies"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除未到貨明細的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delNotInStockModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="modal-title border-start ps-2 border-5 border-danger text-danger fw-bold"
          >
            刪除
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="mb-3">
            確定要刪除叫貨單單號
            <span v-for="data in selectHeaders" :key="data.id"
              >{{ data.id }}&ensp;</span
            >未到貨的數量嗎 ?
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="delNotInStock"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 檢視供應商的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="checkSupplierInfoModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">供應商資訊</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul class="fw-bolder">
            <li class="mb-2">名稱: {{ supplierInfo.name }}</li>
            <li class="mb-2">負責人: {{ supplierInfo.contactPerson }}</li>
            <li class="mb-2">地址: {{ supplierInfo.address }}</li>
            <li class="mb-2">銀行帳號: {{ supplierInfo.bankAccount }}</li>
            <li class="mb-2">電話: {{ supplierInfo.phoneNumber }}</li>
            <li class="mb-2">備註: {{ supplierInfo.note }}</li>
          </ul>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            了解
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 全部入庫的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="allInStockModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">全部入庫</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="alert alert-warning">
            <p class="mb-3" v-if="selectHeaders.length > 0">
              選擇到的叫貨單:
              <span v-for="data in selectHeaders" :key="data.id"
                >{{ data.id }}&ensp;</span
              >
            </p>
            <p v-if="selectBodies.length > 0">
              選擇到的明細:
              <span v-for="data in selectBodies" :key="data.id"
                >{{ data.id }}&ensp;</span
              >
            </p>
          </div>
          確定要將這些全部入庫 ?
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success me-2"
            @click="allInStock(true)"
          >
            入庫並配單
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="allInStock(false)"
          >
            入庫
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 合併叫貨單的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="mergeHeadersModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">合併叫貨單</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 請選擇預設帶入叫貨單資訊 -->
          <select
            class="form-select mb-3"
            v-model="mergeHeadersData.selectHeader"
          >
            <option value="null" disabled>請選擇預設帶入叫貨單資訊</option>
            <option :value="data" v-for="data in selectHeaders" :key="data.id">
              叫貨單編號: {{ data.id }}
            </option>
          </select>
          <!-- 運費/金額調整 -->
          <div class="row">
            <div class="col-6 p-0">
              <div class="form-floating mb-3">
                <input
                  onwheel="this.blur()"
                  type="number"
                  class="form-control"
                  id="MergeHeaderShippingCost"
                  placeholder="運費"
                  min="0"
                  v-model="mergeHeadersData.shippingCost"
                  @input="
                    mergeHeadersData.shippingCost = $methods.numberToFixed(
                      mergeHeadersData.shippingCost
                    )
                  "
                />
                <label for="MergeHeaderShippingCost">運費</label>
              </div>
            </div>
            <div class="col-6 p-0">
              <div class="form-floating mb-3">
                <input
                  onwheel="this.blur()"
                  type="number"
                  class="form-control"
                  id="MergeHeaderAdjustment"
                  placeholder="金額調整"
                  v-model="mergeHeadersData.adjustment"
                  @change="
                    mergeHeadersData.adjustment = $methods.numberToFixed(
                      mergeHeadersData.adjustment
                    )
                  "
                />
                <label for="MergeHeaderAdjustment">金額調整</label>
              </div>
            </div>
          </div>
          <!-- 備註 -->
          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder="備註"
              id="MergeHeaderNote"
              style="height: 100px"
              maxlength="300"
              v-model="mergeHeadersData.note"
            ></textarea>
            <label for="MergeHeaderNote">備註(300字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="mergeHeaders"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 匯出的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="exportExcelModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">匯出 Excel</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p v-if="d4DataTable === 'header'">
            確定要將這些叫貨單匯出成 Excel 格式嗎 ?
          </p>
          <p v-else>確定要將這些叫貨單明細匯出成 Excel 嗎?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="exportExcel"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 表格可點選區介紹 modal -->
  <div
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="introductionModal"
  >
    <div class="modal-dialog" :style="scrollWidth">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">表格可點選區域</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <img
              src="@/assets/images/table-introduction/purchaseOrder.jpg"
              style="max-width: 100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 時間區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_TimeSwitch"
                v-model="advancedSearchData.timeRange.switch"
              />
              <label class="form-check-label" for="AS_TimeSwitch"
                >時間區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.timeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_StartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.timeRange.startTime"
                  />
                  <label for="AS_StartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_EndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.timeRange.endTime"
                  />
                  <label for="AS_EndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 商品名稱 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="AS_Name"
              maxlength="100"
              placeholder="商品名稱(有包含即可)(100字)"
              v-model="advancedSearchData.merchandiseName"
            />
            <label for="AS_Name">商品名稱(有包含即可)(100字)</label>
          </div>
          <!-- 供應商 -->
          <div class="form-floating mb-3" v-if="!suppliers.loading">
            <select
              class="form-select"
              id="AS_Supplier"
              v-model="advancedSearchData.supplier"
            >
              <option :value="null">不選擇</option>
              <option
                :value="data"
                v-for="data in suppliers.data"
                :key="data.id"
              >
                {{ data.name }}
              </option>
            </select>
            <label for="AS_Supplier">供應商</label>
          </div>
          <AreaLoading v-else></AreaLoading>
          <!-- 篩選條件 -->
          <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <li
                v-for="data in singleSelectsArray"
                :key="data.id"
              >
                <div class="row border-bottom" v-if="this.d4DataTable === 'header' && data.id === 'headerStatus' ||
                  this.d4DataTable === 'body' && data.id === 'bodyStatus'">
                  <div class="col-3 my-2">{{ data.key }}</div>
                  <div class="col-9">
                    <div
                      class="select-radio my-2"
                      v-for="select in data.value"
                      :key="select.id"
                    >
                      <label
                      >
                        <input
                          type="radio"
                          :name="data.id"
                          :value="select.value"
                          v-model="advancedSearchData.singleSelect[data.id]"
                        />
                        <span class="radio-style">{{ select.value }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import { FilterMatchMode } from 'primevue/api';
// components
import FieldFilter from '../../../components/tools/FieldFilter.vue'
// mixin
import { Permission } from '@/methods/mixins/permission';

export default {
  components: {DataTable, Column, ColumnGroup, Row, FieldFilter},
  data() {
    return {
      // modal
      editBodyModal: {},
      editHeaderModal: {},
      delBodiesModal: {},
      delNotInStockModal: {},
      checkSupplierInfoModal: {},
      allInStockModal: {},
      mergeHeadersModal: {},
      exportExcelModal: {},
      introductionModal: {},
      advancedSearchModal: {},
      // data
      serverToken: '',
      storeId: 0,
      // dataTable
      scrollWidth: 0,
      scrollHeight: 0,
      dataTabelLoading: false,
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      // modal 內呈現選擇到的物件資訊
      propsSelectInfos: {
        show: 'id',
        data: []
      },
      selectHeaders: [],
      selectBodies: [],
      purchaseOrderList: [],
      // * 叫貨單單身表格 (start)
      purchaseOrderBodies: [],
      d4DataTable: null,
      // * 叫貨單單身表格 (end)
      expandedRows: [],
      // 供應商
      suppliers: {
        loading: false,
        data: []
      },
      // 編輯單頭資料
      editHeader: {
        id: 0,
        supplierId: 0,
        shippingCost: 0,
        adjustment: 0,
        note: '',
        bodyTotal: 0,
        shouldBePay: 0
      },
      // 編輯單身資料
      editbody: {
        id: 0,
        name: '',
        cost: 0,
        style: '',
        quantity: 0,
        stockInQuantity: 0,
        purchaseOrderHeaderId: 0
      },
      // 檢視供應商
      supplierInfo: {},
      // 合併叫貨單
      mergeHeadersData: {
        selectHeader: null,
        shippingCost: 0,
        adjustment: 0,
        note: ''
      },
      // 進階搜尋
      advancedSearchData: {
        timeRange: {
          switch: true,
          startTime: null,
          endTime: null
        },
        merchandiseName: '',
        supplier: null,
        singleSelect: {
          headerStatus: '未完成入庫',
          bodyStatus: '未完成入庫'
        }
      },
      recordAdvancedSearchData: {},
      // 單選篩選陣列
      singleSelectsArray: [],
    }
  },
  mixins: [Permission],
  created() {
    this.initialization()
    this.getPrefer()
    this.getStoreInfo()
  },
  mounted() {
    this.createModals([
      'editBodyModal', 'editHeaderModal', 'delBodiesModal', 'delNotInStockModal',
      'checkSupplierInfoModal', 'allInStockModal', 'mergeHeadersModal', 'exportExcelModal',
      'introductionModal', 'advancedSearchModal'
    ])
    this.changeTableHeigth()
    // 監聽視窗異動
    window.addEventListener("resize", this.changeTableHeigth)
  },
  unmounted() {
    // 移除監聽視窗異動
    window.removeEventListener("resize", this.changeTableHeigth)
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-purchaseOrder-local')
    localStorage.removeItem('dt-state-purchaseOrder-bodies-local')
  },
  watch: {
    // 編輯運費
    'editHeader.shippingCost'(val) {
      let shippingCost = val ? val : 0
      let adjustment = this.editHeader.adjustment ? this.editHeader.adjustment : 0
      this.editHeader.shouldBePay = this.$methods.numberToFixed(shippingCost + parseFloat(this.editHeader.bodyTotal) + adjustment)
    },
    // 編輯金額調整
    'editHeader.adjustment'(val) {
      let shippingCost = this.editHeader.shippingCost ? this.editHeader.shippingCost : 0
      let adjustment = val ? val : 0
      this.editHeader.shouldBePay = this.$methods.numberToFixed(shippingCost + parseFloat(this.editHeader.bodyTotal) + adjustment)
    },
    // 合併叫貨單選擇預設帶入
    'mergeHeadersData.selectHeader'(val) {
      if (val) {
        this.mergeHeadersData.shippingCost = val.shippingCost
        this.mergeHeadersData.adjustment = val.adjustment
      }
    },
    // 若取到偏好 > 預設表格呈現模式列表 > 找到預設值並附值給 d4DataTable
    d4DataTableList: {
      handler(val, oldval) {
        console.log(this.d4DataTable)
        console.log(oldval)
        if (this.d4DataTable !== null) return
        val.forEach(item => {
          if (item.selected) this.d4DataTable = item.value
        })
      },
      deep: true
    },
  },
  computed: {
    // 偏好 > 表格預設顯示筆數
    D4Row() {
      let D4Row = 20
      this.$store.state.Prefer.p_purchaseOrder.rowCount.forEach(item => {
        if (item.selected) D4Row = item.value
      })
      return parseInt(D4Row)
    },
    // 偏好 > 進階搜尋預設時間區間
    timeRange() {
      return parseInt(this.$store.state.Prefer.p_purchaseOrder.searchTimeRange[0].value)
    },
    // 偏好 > 表格模式列表
    d4DataTableList() {
      return this.$store.state.Prefer.p_purchaseOrder.d4DataTable
    },
    // 偏好 > 表格欄位 (單頭模式)
    fieldFilter() {
      return this.$store.state.Prefer.p_purchaseOrder.fieldFilter
    },
    // 偏好 > 表格欄位 (單身模式)
    fieldFilterB() {
      return this.$store.state.Prefer.p_purchaseOrder.fieldFilterB
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      // 介紹表格可點選區寬度
      this.scrollWidth = `max-width: ${window.innerWidth - 20}px`
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-purchaseOrder-local')
      // 紀錄進階搜尋單選條件
      this.singleSelectsArray = [
        {
          id: 'headerStatus',
          key: '叫貨單狀態',
          value: [
            {
              id: 'A1',
              value: '未完成入庫'
            },
            {
              id: 'A2',
              value: '已完成入庫'
            },
            {
              id: 'A3',
              value: '所有'
            },
          ]
        },
        {
          id: 'bodyStatus',
          key: '叫貨明細狀態',
          value: [
            {
              id: 'B1',
              value: '未完成入庫'
            },
            {
              id: 'B2',
              value: '已完成入庫'
            },
            {
              id: 'B3',
              value: '所有'
            },
          ]
        }
      ]
      // 多紀錄進階搜尋
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    // dataTable 內容高度計算
    changeTableHeigth() {
      // 紀錄 dataTabel 高度 (40px: 最上面 marginTop, 170px: 表格中除了內容其餘多的)
      switch (this.d4DataTable) {
        case 'header':
          this.scrollHeight = window.innerHeight - document.getElementById('PurchaseOrderTopArea').clientHeight - 200
          break;
        case 'body':
          this.scrollHeight = window.innerHeight - document.getElementById('PurchaseOrderTopArea').clientHeight - 165
          break;
        default:
          throw new Error('PurchaseOrder.vue > changeTableHeigth > d4DataTable 未處理')
      }
    },
    showModal(status, item) {
      if (status === 'editHeader') {
        // 編輯單頭
        this.selectHeaders = []
        this.selectHeaders.push(item)
        this.editHeader = {
          id: item.id,
          supplierId: item.supplierId,
          shippingCost: item.shippingCost,
          adjustment: item.adjustment,
          note: item.note,
          bodyTotal: 0,
          shouldBePay: 0
        }
        item.purchaseOrderBodies.forEach(item => {
          this.editHeader.bodyTotal += item.cost * item.quantity
        })
        this.editHeader.shouldBePay = this.$methods.numberToFixed(this.editHeader.bodyTotal + this.editHeader.shippingCost + this.editHeader.adjustment)
        this.editHeader.bodyTotal = this.$methods.numberToFixed(this.editHeader.bodyTotal)
        this.editHeaderModal.show()
      } else if (status === 'editBody') {
        // 編輯單身
        this.editbody = {
          id: item.id,
          name: item.merchandiseName,
          cost: item.cost,
          style: '',
          quantity: item.quantity,
          stockInQuantity: item.stockInQuantity,
          purchaseOrderHeaderId: item.purchaseOrderHeaderId
        }
        item.stock.merchandiseStyles.forEach(item => {
          this.editbody.style += `${item.name} `
        })
        this.editBodyModal.show()
      } else if (status === 'batchDel') {
        // 批次刪除
        if (this.selectHeaders.length === 0 && this.selectBodies.length === 0) return this.SweetAlert('other', '請至少選擇一筆叫貨單或明細')
        this.delBodiesModal.show()
      } else if (status === 'singleDel') {
        // 單個刪除
        this.selectBodies = []
        this.selectBodies.push(item)
        this.delBodiesModal.show()
      } else if (status === 'delNotInStock') {
        // 刪除未到貨數量
        this.selectHeaders = []
        this.selectHeaders.push(item)
        this.delNotInStockModal.show()
      } else if (status === 'checkSupplierInfo') {
        // 檢視供應商
        if (this.currentParticipantPermission && !this.currentParticipantPermission.accessSupplier) return this.SweetAlert('other', '您尚未有此權限')
        this.supplierInfo = item
        this.checkSupplierInfoModal.show()
      } else if (status === 'allInStock') {
        // 全部入庫
        if (this.selectHeaders.length === 0 && this.selectBodies.length === 0) return this.SweetAlert('other', '請至少選擇一筆叫貨單或明細')
        this.allInStockModal.show()
      } else if (status === 'mergeHeaders') {
        // 合併叫貨單
        if (this.selectHeaders.length < 2) return this.SweetAlert('other', '請至少選擇兩筆叫貨單')
        let D4SupplierId = this.selectHeaders[0].supplierId
        const error = this.selectHeaders.some(item => {
          return D4SupplierId !== item.supplierId
        })
        if (error) return this.SweetAlert('other', '請選相同供應商')
        this.mergeHeadersData = {
          selectHeader: null,
          shippingCost: 0,
          adjustment: 0,
          note: ''
        }
        this.mergeHeadersModal.show()
      } else if (status === 'exportExcel') {
        // 匯出 excel
        if (this.d4DataTable === 'header' && this.selectHeaders.length === 0) return this.SweetAlert('other', '請至少選擇一筆叫貨單')
        else if (this.d4DataTable === 'body' && this.selectBodies.length === 0) return this.SweetAlert('other', '請至少選擇一筆叫貨單明細')
        if (this.d4DataTable === 'header') this.propsSelectInfos.data = this.selectHeaders
        else this.propsSelectInfos.data = this.selectBodies
        this.exportExcelModal.show()
      } else if (status === 'advancedSearch') {
        // 進階搜尋
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      }
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 取得賣場資訊
    getStoreInfo() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getSuppliers}'
        }
      ]
      this.suppliers = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.storeInfo = storeInfo
            // 供應商
            vm.suppliers = {
              loading: false,
              data: storeInfo.suppliers
            }
            // 先取得預設時間區間
            vm.computedD4TimeRange()
            vm.advancedSearch()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // * 更新資料 (start)
    /**
     * 更新資料
     * @updateItem 要更新的物件
     * @modal 需要關閉的 modal
     */
    updateData(updateItem, modal) {
      this.$methods.switchLoading('show')
      let data = []
      let ids = []
      switch (updateItem) {
        case 'purchaseOrderHeader':
          this.selectHeaders.forEach(header => { ids.push(header.id) })
          data = [{
            ids: ids,
            type: 15,
            methods: '{getSupplier}'
          }]
          break;
        default:
          throw new Error('purchaseOrder.vue > updateData > updateItem 未處理')
      }
      // 取要更新的資料
      const updateData = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      };
      const vm = this
      $.ajax({
        type: "POST",
        async: true,
        url: updateData,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            const merchandises = res.data[0].objects
            vm.sortoutUpdateData(merchandises, updateItem, modal)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 整理更新資料
    sortoutUpdateData(updatedInfo, updateItem, modal) {
      switch (updateItem) {
        case 'purchaseOrderHeader':
          this.purchaseOrderList.forEach(origin => {
            updatedInfo.forEach(update => {
              if (origin.id === update.id) {
                origin.adjustment = update.adjustment ? update.adjustment : 0
                origin.note = update.note
                origin.shippingCost = update.shippingCost ? update.shippingCost : 0
                origin.supplier = update.supplier
                origin.supplierName = update.supplier.name
                origin.supplierId = update.supplier.id
                origin.shouldBePay = update.shippingCost + update.adjustment + origin.price
              }
            })
          })
          this.selectHeaders = []
          break;
        case 'purchaseOrderBody':
          this.updateBodyData()
          break;
        case 'delBodies':
          const delBodyIds = updatedInfo
          this.updateDelBodies(delBodyIds)
          this.selectBodies = []
          this.selectHeaders = []
          break;
        case 'delNotInStock':
          const headerId = this.selectHeaders[0].id
          this.purchaseOrderList.some(origin => {
            if (headerId === origin.id) {
              origin.purchaseOrderBodies.forEach(body => {
                body.quantity = body.stockInQuantity
              })
              return true
            }
          })
          break;
        case 'stockInQuantity':
          this.updateStockInQuantity()
          this.selectHeaders = []
          this.selectBodies = []
          break;
        default:
          throw new Error('purchaseOrder.vue > sortoutUpdateData > updateItem 未處理')
      }
      this.SweetAlert('200')
      if (modal) this[modal].hide()
      this.$methods.switchLoading('hide')
    },
    // 更新單身資料
    updateBodyData() {
      // 紀錄單身總額
      let bodiesTotal = 0
      // 紀錄單身是否全到貨
      let deleteIndex = null
      this.purchaseOrderList.some((item, index) => {
        if (this.editbody.purchaseOrderHeaderId === item.id) {
          // 紀錄單身全到貨的數量 (筆數)
          let allInStockQuantity = 0
          item.purchaseOrderBodies.forEach(body => {
            if (body.id === this.editbody.id) {
              // 編輯單身後，將值回單身
              body.cost = this.editbody.cost,
              body.quantity = Math.trunc(this.editbody.quantity),
              body.stockInQuantity = Math.trunc(this.editbody.stockInQuantity)
            }
            // 重新計算單身總額
            bodiesTotal += body.quantity * body.cost
            // 檢查所有單身的未到貨數量是不是等於 0
            if (Math.max(body.quantity - body.stockInQuantity, 0) === 0) allInStockQuantity ++
          })
          // 計算完的結果帶回單頭
          item.price = bodiesTotal
          item.shouldBePay = item.shippingCost + item.price + item.adjustment
          // 判斷單身全到貨的數量(筆數)等於是否單身長度，若等於代表他的叫貨單已全到貨
          if (allInStockQuantity === item.purchaseOrderBodies.length) deleteIndex = index
          return true
        }
      })
      // 若 deleteIndex 有數值代表那張單在 "進階搜尋 > 叫貨單狀態為未完成入庫的叫貨單" 的情況下要被刪除
      if (this.advancedSearchData.singleSelect.headerStatus === '未完成入庫的叫貨單' && deleteIndex !== null) this.purchaseOrderList.splice(deleteIndex, 1)
    },
    // 更新刪除單身資料
    updateDelBodies(delBodyIds) {
      if (this.d4DataTable === 'header') {
        this.purchaseOrderList = this.purchaseOrderList.filter(header => {
          header.purchaseOrderBodies = header.purchaseOrderBodies.filter(body => {
            let delId = null
            delBodyIds.forEach(delBodyId => {
              if (body.id === delBodyId) delId = body.id
            })
            return delId === null 
          })
          return header.purchaseOrderBodies.length > 0
        })
      } else {
        this.purchaseOrderBodies = this.purchaseOrderBodies.filter(body => {
          let delId = null
          delBodyIds.forEach(delBodyId => {
            if (body.id === delBodyId) delId = body.id
          })
          return delId === null
        })
      }
    },
    // 更新全部入庫
    updateStockInQuantity() {
      if (this.d4DataTable === 'header') {
        this.purchaseOrderList.forEach(originHeader => {
          originHeader.purchaseOrderBodies.forEach(originBody => {
            this.selectHeaders.forEach(header => {
              if (originHeader.id === header.id) {
                originBody.stockInQuantity = originBody.quantity
              }
            })
            this.selectBodies.forEach(body => {
              if (originBody.id === body.id) {
                originBody.stockInQuantity = originBody.quantity
              }
            })
          })
        })
      } else {
        this.purchaseOrderBodies.forEach(origin => {
          this.selectBodies.forEach(body => {
            if (origin.id === body.id) {
              origin.stockInQuantity = origin.quantity
            }
          })
        })
      }
    },
    // * 更新資料 (end)
    // * 取資料 (start)
    // 進階搜尋
    advancedSearch(closeModal) {
      this.dataTabelLoading = true
      this.$methods.switchLoading('show')
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      this.selectHeaders = []
      this.selectBodies = []
      const query = {"columns":[{"columnName":"id","siteObjectType":15}],"distinct":true,"group":{"groups":[{"column":{"columnName":"StoreId","siteObjectType":15},"operator":{"operator":"=","type":1,"value":this.storeId}}],"operator":1},"joins":[{"leftColumn":{"columnName":"id","siteObjectType":15},"rightColumn":{"columnName":"PurchaseOrderHeaderId","siteObjectType":28},"type":0}],"siteObjectType":15,"sqlCommandType":1}
      // 時間區間
      if (this.advancedSearchData.timeRange.switch) {
        if (this.advancedSearchData.timeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.startTime} 00:00:00`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 15
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.timeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.endTime} 23:59:59`) / 1000
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 15
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      // 叫貨明細單狀態
      switch(this.d4DataTable === 'header' ? this.advancedSearchData.singleSelect.headerStatus : this.advancedSearchData.singleSelect.bodyStatus) {
        case '未完成入庫':
          query.group.groups.push({
            column: {
              columnName: 'stockInQuantity',
              siteObjectType: 28
            },
            operator: {
              operator: "<",
              type: 1,
              value: {
                columnName: "Quantity",
                siteObjectType: 28
              }
            }
          })
          break;
      }
      if (closeModal) this.advancedSearchModal.hide()
      this.getPurchaseOrderList(query)
    },
    // 計算預設時間區間
    computedD4TimeRange() {
      this.advancedSearchData.timeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.timeRange.startTime = this.$methods.moment().subtract(this.timeRange, 'days').format('YYYY-MM-DD')
    },
    // 取得叫貨資訊
    getPurchaseOrderList(query) {
      const vm = this
      const getPurchaseOrderListApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          query: query,
          methods: '{getSupplier,getPurchaseOrderBodies{getMerchandise{getMerchandisePictureLinks},getStock{getMerchandiseStyles}}}'
        }
      ]
      this.purchaseOrderList = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getPurchaseOrderListApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const purchaseOrderList = res.data[0].objects
            vm.purchaseOrderMap(purchaseOrderList)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 整理叫貨列表
    purchaseOrderMap(purchaseOrderList) {
      purchaseOrderList.forEach(item => {
        // 原資料
        const object = {
          adjustment: item.adjustment ? item.adjustment : 0,
          createTime: this.$methods.moment(item.createTime).format('YYYY-MM-DD HH:MM:ss'),
          creatorId: item.creatorId,
          deleted: item.deleted,
          id:item.id,
          note: item.note,
          purchaseOrderBodies: item.purchaseOrderBodies,
          price: 0,
          shippingCost: item.shippingCost ? item.shippingCost : 0,
          storeId: item.storeId,
          supplier: item.supplier,
          supplierName: item.supplier.name,
          supplierId: item.supplierId,
          shouldBePay: 0,
        }
        // 需特別處理
        // 進階搜尋 > 商品名稱 (顯示模式為單頭)
        let matchMerchandiseName = false
        if (!this.advancedSearchData.merchandiseName) matchMerchandiseName = true
        else {
          matchMerchandiseName = object.purchaseOrderBodies.some(item => {
            // 進階搜尋 > 判斷單身內有沒有符合的商品
            console.log(item.merchandise.name, this.advancedSearchData.merchandiseName, item.merchandise.name.match(this.advancedSearchData.merchandiseName))
            if (item.merchandise.name.match(this.advancedSearchData.merchandiseName)) return true
          })
        }
        object.purchaseOrderBodies.forEach(item => {
          // 總金額
          object.price += item.cost * item.quantity
          // 商品圖
          item.frontImageHash = null
          item.merchandise.merchandisePictureLinks.some(image => {
            if (image.front) return item.frontImageHash = image.imageHash
          })
          // 商品名稱
          item.merchandiseName = item.merchandise.name
          // 入庫數量
          item.warehousingQuantity = 0
        })
        // 計算單頭應付金額
        object.shouldBePay = object.shippingCost + object.adjustment + object.price

        // 進階搜尋 > 供應商
        let matchSupplier = false
        if ((this.advancedSearchData.supplier && item.supplierId === this.advancedSearchData.supplier.id) || !this.advancedSearchData.supplier) matchSupplier = true
        else matchSupplier = false
        if (matchMerchandiseName && matchSupplier) this.purchaseOrderList.push(JSON.parse(JSON.stringify(object)))
      })
      if (this.d4DataTable === 'header') {
        this.$methods.switchLoading('hide')
        this.dataTabelLoading = false
      } else {
        this.sortoutBodies()
      }
      this.tableDataFilter()
    },
    // 整理叫貨單單身表格資料
    sortoutBodies() {
      this.purchaseOrderBodies = []
      this.purchaseOrderList.forEach(header => {
        header.purchaseOrderBodies.forEach(body => {
          // 紀錄單頭建立時間
          body.createTime = header.createTime
          // 紀錄單頭供應商
          body.supplier = header.supplier
          body.supplierName = header.supplierName
          body.supplierId = header.supplierId
          // 紀錄款式
          body.merchandiseStyle = ''
          body.stock.merchandiseStyles.forEach(style => {
            body.merchandiseStyle += `${style.name} `
          })

          // 進階搜尋 > 商品名稱 (顯示模式為單身)
          let matchMerchandiseName = false
          if (this.advancedSearchData.merchandiseName) {
            if (body.merchandiseName.match(this.advancedSearchData.merchandiseName)) matchMerchandiseName = true
          } else matchMerchandiseName = true
          
          if (matchMerchandiseName) this.purchaseOrderBodies.push(body)
        })
      })
      this.$methods.switchLoading('hide')
      this.dataTabelLoading = false
    },
    // 處理叫貨單/明細狀態進階搜尋
    tableDataFilter() {
      if (this.d4DataTable === 'header') {
        // 叫貨單模式
        if (this.advancedSearchData.singleSelect.headerStatus === "已完成入庫") {
          this.purchaseOrderList = this.purchaseOrderList.filter(header => {
            let completed = true
            header.purchaseOrderBodies.forEach(body => {
              if (Math.max(body.quantity - body.stockInQuantity, 0) > 0) completed = false
            })
            console.log(completed)
            return completed
          })
        }
      } else {
        // 明細模式
        switch(this.advancedSearchData.singleSelect.bodyStatus) {
          case '未完成入庫':
            this.purchaseOrderBodies = this.purchaseOrderBodies.filter(item => Math.max(item.quantity - item.stockInQuantity, 0) > 0)
            break;
          case '已完成入庫':
            this.purchaseOrderBodies = this.purchaseOrderBodies.filter(item => Math.max(item.quantity - item.stockInQuantity, 0) === 0)
            break;
        }
      }
    },
    // * 取資料 (end)
    // 展開全部
    expandAll() {
      this.expandedRows = this.purchaseOrderList.filter(item => item.id);
    },
    // 收合全部
    collapseAll() {
      this.expandedRows = []
    },
    // 更新入庫 input 的值
    updateWarehousingQuantity(mode, status, item) {
      if (mode === 'single') {
        // 單一操作
        console.log(item)
        if (status === 'min') {
          // 最小
          item.warehousingQuantity = 0
        } else {
          // 最大
          item.warehousingQuantity = Math.max(item.quantity - item.stockInQuantity, 0)
        }
      } else {
        // 多選操作
        this.purchaseOrderList.forEach(item => {
          item.purchaseOrderBodies.forEach(body => {
            if (status === 'min') {
              // 最小
              body.warehousingQuantity = 0
            } else {
              // 最大
              body.warehousingQuantity = Math.max(body.quantity - body.stockInQuantity, 0)
            }
          })
        })
      }
    },
    // 入庫
    stockInBodies(status, item, allocate) {
      const vm = this;
      const stockInBodiesApi = `${process.env.VUE_APP_API}/purchaseOrder/updateBody`;
      const header = {
        authorization: this.serverToken
      };
      let data = []
      if (status === 'all') {
        // 確定入庫按鈕
        this.purchaseOrderList.forEach(item => {
          item.purchaseOrderBodies.forEach(body => {
            if (body.warehousingQuantity !== 0) {
              data.push({
                id: body.id,
                stockInQuantity: body.warehousingQuantity
              })
            }
          })
        })
        if (data.length === 0) return this.SweetAlert('other', '您尚未輸入任何入庫數量')
      } else {
        // 單身內的全到
        if ((item.quantity - item.stockInQuantity) > 0) {
          data.push({
            id: item.id,
            stockInQuantity: 'max',
            allocate: allocate
          })
        }
        if (data.length === 0) return this.SweetAlert('other', '此明細已完全到貨嘍')
      }
      this.$methods.switchLoading('show')
      $.ajax({
        type: "PUT",
        async: true,
        url: stockInBodiesApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            if (status === 'all') vm.advancedSearch()
            else {
              item.stockInQuantity = item.quantity
              // 紀錄要被刪除的單頭位置
              let deleteIndex = null
              // 檢查此單頭內的所有單身未到貨數量是否為 0
              vm.purchaseOrderList.some((header, index) => {
                if (header.id === item.purchaseOrderHeaderId) {
                  // 紀錄單身全到貨筆數
                  let allInStockQuantity = 0
                  header.purchaseOrderBodies.forEach(body => {
                    if (Math.max(body.quantity - body.stockInQuantity, 0) === 0) allInStockQuantity ++
                  })
                  // 判斷單身全到貨的數量(筆數)等於是否單身長度，若等於代表他的叫貨單已全到貨
                  if (allInStockQuantity === header.purchaseOrderBodies.length) deleteIndex = index
                  return true
                }
              })
              // 若 deleteIndex 有數值代表那張單在 "進階搜尋 > 叫貨單狀態為未完成入庫的叫貨單" 的情況下要被刪除
              if (vm.advancedSearchData.singleSelect.headerStatus === '未完成入庫的叫貨單' && deleteIndex !== null) vm.purchaseOrderList.splice(deleteIndex, 1)
            }
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 編輯單頭
    updateHerder() {
      const vm = this;
      const updateHerderApi = `${process.env.VUE_APP_API}/purchaseOrder/updateHeader`;
      const header = {
        authorization: this.serverToken
      };
      const data = [{
        id: this.editHeader.id,
        supplierId: this.editHeader.supplierId,
        shippingCost: this.editHeader.shippingCost,
        adjustment: this.editHeader.adjustment,
        note: this.editHeader.note
      }]
      $.ajax({
        type: "PUT",
        async: true,
        url: updateHerderApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.updateData('purchaseOrderHeader', 'editHeaderModal')
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 編輯單身
    updateBody(allocate) {
      const vm = this;
      const updateBodyApi = `${process.env.VUE_APP_API}/purchaseOrder/updateBody`;
      const header = {
        authorization: this.serverToken
      };
      const data = [{
        id: this.editbody.id,
        cost: this.editbody.cost,
        quantity: this.editbody.quantity,
        stockInQuantity: this.editbody.stockInQuantity,
        allocate: allocate
      }]
      $.ajax({
        type: "PUT",
        async: true,
        url: updateBodyApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.sortoutUpdateData(null, 'purchaseOrderBody', 'editBodyModal')
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 批次/單次刪除
    delBodies() {
      const vm = this;
      const delBodiesApi = `${process.env.VUE_APP_API}/purchaseOrder/deleteBodies`;
      const header = {
        authorization: this.serverToken
      };
      let bodyIds = []
      this.selectHeaders.forEach(item => {
        item.purchaseOrderBodies.forEach(body => {
          bodyIds.push(body.id)
        })
      })
      this.selectBodies.forEach(item => {
        const canNotAddBodyIds = bodyIds.some(body => {
          return body === item.id
        })
        if (!canNotAddBodyIds) bodyIds.push(item.id)
      })
      console.log(bodyIds)
      let data = new FormData()
      data.append('bodyIds', bodyIds.toString())
      $.ajax({
        type: "DELETE",
        async: true,
        url: delBodiesApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.sortoutUpdateData(bodyIds, 'delBodies', 'delBodiesModal')
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 刪除未到貨數量
    delNotInStock() {
      const vm = this;
      const delNotInStockApi = `${process.env.VUE_APP_API}/purchaseOrder/setQuantityAsStockInQuantity`;
      const header = {
        authorization: this.serverToken
      };
      const data = new FormData()
      data.append('headerIds', this.selectHeaders[0].id)
      $.ajax({
        type: "PUT",
        async: true,
        url: delNotInStockApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.sortoutUpdateData(null, 'delNotInStock', 'delNotInStockModal')
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 全部入庫
    allInStock(allocate) {
      const vm = this;
      const allInStockApi = `${process.env.VUE_APP_API}/purchaseOrder/updateBody`;
      const header = {
        authorization: this.serverToken
      };
      let data = []
      this.selectHeaders.forEach(item => {
        item.purchaseOrderBodies.forEach(body => {
          if (body.quantity - body.stockInQuantity > 0) {
            data.push({
              id: body.id,
              stockInQuantity: 'max',
              allocate: allocate
            })
          }
        })
      })
      this.selectBodies.forEach(item => {
        const canNotPushData = data.some(body => {
          return body.bodyId === item.id
        })
        if (!canNotPushData && (item.quantity - item.stockInQuantity) > 0) {
          data.push({
            id: item.id,
            stockInQuantity: 'max',
            allocate: allocate
          })
        }
      })
      console.log(data)
      this.$methods.switchLoading('show')
      $.ajax({
        type: "PUT",
        async: true,
        url: allInStockApi,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.sortoutUpdateData(null, 'stockInQuantity', 'allInStockModal')
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 合併叫貨單
    mergeHeaders() {
      this.$methods.switchLoading('show')
      const vm = this;
      const mergeHeadersApi = `${process.env.VUE_APP_API}/purchaseOrder/mergeHeaders`;
      const header = {
        authorization: this.serverToken
      };
      let headerIds = []
      this.selectHeaders.forEach(item => { headerIds.push(item.id) })
      const data = new FormData()
      data.append('headerIds', headerIds.toString())
      data.append('shippingCost', this.mergeHeadersData.shippingCost)
      data.append('adjustment', this.mergeHeadersData.adjustment)
      data.append('note', this.mergeHeadersData.note)
      $.ajax({
        type: "POST",
        async: true,
        url: mergeHeadersApi,
        headers: header,
        data: data,
        processData: false,
        contentType: false,
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            // todo 合併叫貨單 > 後端需回傳 herderId
            vm.mergeHeadersModal.hide()
            vm.advancedSearch()
            vm.selectHeaders = []
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 匯出 excel
    exportExcel() {
      let ids = []
      let siteObjectTypeIndex = 0
      if (this.d4DataTable === 'header') {
        this.selectHeaders.forEach(item => {
          siteObjectTypeIndex = 15
          ids.push(item.id)
        })
      } else {
        this.selectBodies.forEach(item => {
          siteObjectTypeIndex = 28
          ids.push(item.id)
        })
      }
      const exportExcelUrl = `${process.env.VUE_APP_API}/download/exportDataTableExcel?authorization=${this.serverToken}&siteObjectTypeIndex=${siteObjectTypeIndex}&ids=${ids.toString()}`;
      window.open(exportExcelUrl, "_blank");
      this.exportExcelModal.hide()
      this.selectHeaders = []
    },
    // 切換表格模式
    changeMode() {
      if (this.d4DataTable === 'header') this.d4DataTable = 'body'
      else this.d4DataTable = 'header'
      this.advancedSearch()
      this.changeTableHeigth()
    },
  },
}
</script>